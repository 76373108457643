/** @jsx jsx */

import { jsx } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FaShoppingBasket } from "react-icons/fa"

export const Products = ({ props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allAirtable(sort: { fields: data___Product_ID, order: ASC }) {
          totalCount
          edges {
            node {
              data {
                Name
                Price
                Example
                Product_ID
                Distribution
              }
            }
          }
        }
      }
    `
  )

  const tiles = data.allAirtable.edges

  const tileslist = tiles.map(({ node: number }, i) => (
    <li
      key={i}
      sx={{
        transition: "200ms ease-in-out",
        position: "relative",
        // transition: "box-shadow .25s linear",
        "&:hover": {
          transform: "scale(1.23)",
          boxShadow: "0 10px 20px rgba(3,27,78,.2)",
        },
        "&:last-child": {
          gridColumn: `span 3`,
        },
      }}
    >
      <a
        href="#"
        className="tile snipcart-add-item"
        sx={{
          bg: "regularbg",
          color: "white",
          display: "block",
          textAlign: "center",
          padding: 3,
        }}
        data-item-id={number.data.Name}
        data-item-price={number.data.Price}
        data-item-name={number.data.Name}
        data-item-url={`/`}
        data-item-max-quantity={number.data.Distribution || "2"}
      >
        <h2
          sx={{
            fontSize: [4, 5, 7],
            fontWeight: 400,
            textTransform: "uppercase",
            marginBottom: 0,
            marginTop: 0,
            lineHeight: ".875",
          }}
        >
          {number.data.Name}
        </h2>
        <span
          sx={{
            fontSize: ".75em",
            fontStyle: "italic",
            lineHeight: 0,
          }}
        >
          as in
        </span>
        <h4
          sx={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 1,
            textTransform: "uppercase",
            fontFamily: "condensed",
            overflow: "hidden",
            margin: 0,
            lineHeight: 1,
            letterSpacing: ".003em",
            WebkitFontSmoothing: "antialiased",
          }}
        >
          {number.data.Example || "?"}
        </h4>
        <div
          className="bonus"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            bg: "background",
            textAlign: "left",
            opacity: ".2",
          }}
        >
          <span
            sx={{
              fontSize: ".55em",
              color: "navlinks",
            }}
          >
            {number.data.Distribution || "12"}x
          </span>
        </div>
      </a>
    </li>
  ))

  return (
    <ul
      sx={{
        display: "grid",
        gridGap: "2",
        gridTemplateColumns: `repeat(auto-fill, minmax(128px, 1fr) )`,
        // gridTemplateColumns: `repeat(auto-fit, minmax(128px, 1fr))`,
        listStyleType: "none",
        margin: 0,
        padding: 0,
      }}
    >
      {tileslist}
    </ul>
  )
}
