export const distribution = [
  {
    name: "a",
    quantity: 9,
  },
  {
    name: "b",
    quantity: 2,
  },
  {
    name: "c",
    quantity: 2,
  },
  {
    name: "d",
    quantity: 4,
  },
  {
    name: "e",
    quantity: 12,
  },
  {
    name: "f",
    quantity: 2,
  },
  {
    name: "g",
    quantity: 3,
  },
  {
    name: "h",
    quantity: 2,
  },
  {
    name: "i",
    quantity: 9,
  },
  {
    name: "j",
    quantity: 1,
  },
  {
    name: "k",
    quantity: 1,
  },
  {
    name: "l",
    quantity: 4,
  },
  {
    name: "m",
    quantity: 2,
  },
  {
    name: "n",
    quantity: 6,
  },
  {
    name: "o",
    quantity: 8,
  },
  {
    name: "p",
    quantity: 2,
  },
  {
    name: "q",
    quantity: 1,
  },
  {
    name: "r",
    quantity: 6,
  },
  {
    name: "s",
    quantity: 4,
  },
  {
    name: "t",
    quantity: 6,
  },
  {
    name: "u",
    quantity: 4,
  },
  {
    name: "v",
    quantity: 2,
  },
  {
    name: "w",
    quantity: 2,
  },
  {
    name: "x",
    quantity: 1,
  },
  {
    name: "y",
    quantity: 2,
  },
  {
    name: "z",
    quantity: 1,
  },
  {
    name: "blank",
    quantity: 2,
  },
]
