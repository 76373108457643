/** @jsx jsx */
import { jsx } from 'theme-ui'
// import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import TextTruncate from 'react-text-truncate'
// import { TheDebugBox } from './TheDebugBox'
// import { TheLink } from "./TheLink"
import { LinkWrapper } from '.'
import theme from '../gatsby-plugin-theme-ui'

const StyledDiv = styled(motion.div)``

// const StyledLink = styled(motion(Link))``

const thelist = {
	hidden: {
		opacity: 1,
		scale: 0.75
	},
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.1
		}
	}
}

const theitem = {
	hidden: { y: 20, opacity: 0 },
	visible: { y: 0, opacity: 1 }
}

export const ArticleList = ({ items }) => (
	<StyledDiv
		initial="hidden"
		animate="visible"
		variants={thelist}
		sx={{
			// bg: 'icon_red',
			// p: 4,
			display: 'grid',
			gridGap: 4,
			gridTemplateColumns: '1fr'
		}}
	>
		{items.map((item, i) => (
			<Item {...item} key={i} />
		))}
	</StyledDiv>
)

const Item = ({ title, body, generatedOverview, uri, i }) => (
	<motion.div
		key={i}
		sx={{
			bg: 'contrast',
			position: 'relative',
			px: 4,
			py: 3,
			cursor: 'pointer',
			// transition: "all 400ms ease-in-out",
			transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)!important',
			'&:hover': {
				// bg: 'icon_green',
				transform: 'translateY(-5px)',
				boxShadow: 'xl',
				// transition: "all 400ms ease-in-out",
				h2: {
					color: 'accessiblePrimary'
				}
			}
		}}
		variants={theitem}
	>
		<h2 sx={{ fontWeight: '500', mt: 0, mb: 3 }}>{title}</h2>
		<TextTruncate
			line={2}
			element="span"
			truncateText="…"
			text={generatedOverview}
			// textTruncateChild={<a href="#">Read on</a>}
			sx={{
				fontSize: theme.fontSizes[2],
				lineHeight: theme.lineHeights.normal,
				color: 'textMuted',
				fontStyle: 'italic'
			}}
		/>
		<LinkWrapper to={`/${uri}/`}>Continue Reading</LinkWrapper>
	</motion.div>
)
