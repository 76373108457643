/** @jsx jsx */ 
import React, { Component } from "react";
import { jsx } from 'theme-ui'
import { MdShoppingBasket } from 'react-icons/md'

class HeaderMinor extends Component {
    state = {
        items: 0
    }

    updateItemTotal = (qty) => {
        this.setState({ items: qty })
    }

    componentDidMount() {
        if (window.Snipcart) {
            //this allows it to work when switching pages
            var count = window.Snipcart.api.items.count();
            this.updateItemTotal(count)

            //this allows it to work when you add or change items
            window.Snipcart.subscribe('cart.closed', () => {
                var count = window.Snipcart.api.items.count();
                this.updateItemTotal(count)
            });

            //this allows it to work on refreshing the page
            window.Snipcart.subscribe('cart.ready', (data) => {
                var count = window.Snipcart.api.items.count();
                this.updateItemTotal(count)
            })
        }
    }

    componentWillUnmount () {
        window.Snipcart.unsubscribe('cart.closed');
        window.Snipcart.unsubscribe('cart.ready');
    }
    

    render() {
        return (
          this.state.items > 0 ? 

                <div className="snipcart-summary">
                    <a href="#" className="snipcart-checkout">
                    <MdShoppingBasket size="1.5rem" />
                    <span sx={{
                      bg: 'red.5',
                      color: "white",
                      borderRadius: '9999px',
                      px: 2,
                      py: 1,
                      lineHeight: 0,
                      marginLeft: 1
                    }}>{this.state.items}</span></a>
                </div>
          : null
        )
    }

}

export default HeaderMinor;