/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import { Switch } from "."
import { Sun, Moon } from "../images"

const checkedIcon = (
  <img
    alt="moon indicating dark mode"
    src={Moon}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
      display: "inline",
    }}
  />
)

const uncheckedIcon = (
  <img
    alt="sun indicating light mode"
    src={Sun}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
      display: "inline",
    }}
  />
)

export const TheSwitcher = ({ props }) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = e => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Switch
      aria-label="Toggle dark mode"
      css={{
        bg: `black`,
      }}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      checked={isDark}
      onChange={toggleColorMode}
    />
  )
}
