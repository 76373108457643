/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

const StyledDiv = styled(motion.div)``

const thelist = {
  hidden: {
    opacity: 1,
    scale: 0.75,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

const theitem = {
  hidden: { y: 20, opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

export const CardList = ({ items }) => (
  <StyledDiv
    initial="hidden"
    animate="visible"
    variants={thelist}
    sx={{
      bg: "icon_red",
      p: 4,
      display: "grid",
      gridGap: 4,
      gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
    }}
  >
    {items.map(({ node: item }, i) => (
      <Item {...item} key={i} />
    ))}
  </StyledDiv>
)

const Item = ({ name, i }) => (
  <motion.div
    key={i}
    sx={{
      bg: "primary",
      p: 2,
      cursor: "pointer",
      transition: "all 400ms ease-in-out",
      "&:hover": {
        bg: "icon_green",
        transition: "all 400ms ease-in-out",
      },
    }}
    variants={theitem}
  >
    carl {name.firstName}
  </motion.div>
)
