/** @jsx jsx */
import { jsx } from "theme-ui"

export const Footer = ({ props }) => (
  <footer
    sx={{
      // bg: props.footerbg || 'yellow',
      // p: 3,
      zIndex: 1,
      variant: "styles.Footer",
    }}
  >
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(256px, 1fr))",
        alignItems: "center",
        // maxWidth: 768,
        mx: "auto",
      }}
    >
      <div sx={{ p: 2, textAlign: ["center", "center", "left"] }}>
        Privacy Policy
      </div>
      <div sx={{ p: 2, textAlign: ["center", "center", "right"] }}>
        ©{new Date().getFullYear()} smartassdesign, inc.
      </div>
    </div>
  </footer>
)
