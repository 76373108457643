//TODO: remove styles from here @today
/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import { LogoLockup, TheLink, TheSwitcher } from "."
import CartCount from "./CartCount"

// import theme from "../gatsby-plugin-theme-ui"

// const StyledHeadroom = styled.div`
// 	z-index: 99;
// `

const StyledListLink = styled(GatsbyLink)`
  font-weight: 700;
  &:hover {
    color: "background";
  }
`

//TODO: Also from here

const ListLink = props => (
  <StyledListLink
    as={GatsbyLink}
    to={props.to}
    getProps={({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { "data-active": "true" } : null
    }
    sx={{
      variant: "styles.Navlinks",
    }}
  >
    {props.children}
  </StyledListLink>
)

export const Header = ({ siteTitle, variant = "default", ...props }) => {
  const menuitems = [{ label: "The Tiles", href: "/tiles/" }]

  return (
    <header
      sx={{
        variant: `layout.header.${variant}`,
        height: "var(--heading-height)",
      }}
    >
      <TheLink to="/" sx={{ fontSize: "22px", color: "heading", px: 3 }}>
        <motion.div
          whileHover={{ scale: 1.065 }}
          transition={{ duration: 0.365 }}
        >
          <LogoLockup w="200" />

          <span className="sr-only" sx={{ display: "none" }}>
            {siteTitle}
          </span>
        </motion.div>
      </TheLink>
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          variant: "styles.Navlinks",
        }}
      >
        {menuitems.map((item, i) => (
          <ListLink key={i} to={item.href}>
            {item.label}
          </ListLink>
        ))}

        <CartCount />

        <TheSwitcher />
      </div>
    </header>
  )
}

// { label: 'Articles', href: '/articles/' },
// { label: 'Search Listings', href: '/search-listings/' }
