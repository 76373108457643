/** @jsx jsx */

import { jsx } from "theme-ui"
import { distribution } from "../data/data"

export const DistributionChart = ({ props }) => {
  return [
    <h2>Words</h2>,
    <ul
      sx={{
        width: "500px",
        mx: "auto",
      }}
    >
      {distribution.map((tile, i) => (
        <li
          key={i}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            bg: "yellow.4",
            borderBottom: "1px solid gray",
            paddingY: 2,
            paddingX: 3,
          }}
        >
          <span
            sx={{
              color: "red.5",
              textTransform: "uppercase",
              fontWeight: "600",
            }}
          >
            {tile.name}
          </span>
          <div sx={{ mx: "auto" }} />
          <span>{tile.quantity || null}</span>
        </li>
      ))}
    </ul>,
  ]
}
