import React from "react"
import styled from "@emotion/styled"

export const TheDebugBox = ({ children, ...props }) => {
  const Box = styled.div`
    background: ${props.bg || "red"};
    display: ${props.isDisplayed ? "block" : "none"};
    padding: 3rem;
    pre {
      white-space: pre-wrap;
    }
    [data-debugger="on"] & {
      display: block;
      // background: purple;
    }
  `
  return (
    props.object && (
      <Box {...props}>
        <pre>{JSON.stringify(props.object, null, 2)}</pre>
      </Box>
    )
  )
}
