/** @jsx jsx */
import { Global } from "@emotion/core"
import { Header, Footer, Container } from "."
import { Layout, jsx, Main, Styled } from "theme-ui"
import "../sass/basic.scss"
// import "../sass/snipcart.scss"
// import { redirectTo } from '@reach/router'
// import ColorSwitcher from './ColorSwitcher'
import SEO from "./seo"

export const TheLayout = ({ children, ...props }) => (
  <Styled.root>
    <Global
      styles={`
				body{
          margin: 0;
          -webkit-font-smoothing: antialiased;
				}
				:root {
          --font-body: "Sentinel A, Sentinel B";
          --font-links: "Gotham SSm A,Gotham SSm B";
					--heading-height: 72px;
					--global-border-width: 12px;
					@media (min-width: 540px) {
						--global-border-width: 24px;
						--heading-height: 96px;
					}
				}
			`}
    />
    <SEO title={props.title} description={props.description} />
    <Layout
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header variant={props.headerVariant} />
      <Main sx={{ display: "flex", flex: "1 1 auto", p: 3 }}>
        <Container unconfined={props.unconfined}>{children}</Container>
      </Main>
      <Footer />
    </Layout>
  </Styled.root>
)
