/** @jsx jsx */
import { jsx } from 'theme-ui'
// import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
// import TextTruncate from 'react-text-truncate'
// import { TheDebugBox } from './TheDebugBox'
// import { TheLink } from "./TheLink"
// import { LinkWrapper } from '.'
// import theme from '../gatsby-plugin-theme-ui'

const StyledDiv = styled(motion.div)``

//TODO: componentize the gridlist part
//TODO: switch these images to gatsby image
//TODO: make that gatsby image a component, too.

// const StyledLink = styled(motion(Link))``

const thelist = {
	hidden: {
		opacity: 1,
		scale: 0.75
	},
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.1
		}
	}
}

const theitem = {
	hidden: { y: 20, opacity: 0 },
	visible: { y: 0, opacity: 1 }
}

export const PartnerList = ({ items }) => (
	<StyledDiv
		initial="hidden"
		animate="visible"
		variants={thelist}
		sx={{
			// bg: 'icon_red',
			// p: 4,
			display: 'grid',
			gridGap: 4,
			gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
		}}
	>
		{items.map((item, i) => (
			<Item {...item} key={i} />
		))}
	</StyledDiv>
)

const Item = ({ title, body, featuredImage, uri, i }) => (
	<motion.div
		key={i}
		sx={{
			bg: 'contrast',
			position: 'relative',
			cursor: 'pointer',
			// transition: "all 400ms ease-in-out",
			transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)!important',
			'&:hover': {
				// bg: 'icon_green',
				transform: 'translateY(-5px)',
				boxShadow: 'xl',
				// transition: "all 400ms ease-in-out",
				h2: {
					color: 'primary'
				}
			}
		}}
		variants={theitem}
	>
		<img
			src={`${featuredImage[0].url}?sat=-100&w=400&h=600&fit=crop&crop=entropy`}
			alt={title}
			sx={{
				height: 'auto',
				maxWidth: '100%'
			}}
		/>
		<div sx={{ px: 4, py: 3 }}>
			<h2 sx={{ fontWeight: '500', mt: 0, mb: 3 }}>{title}</h2>
		</div>
	</motion.div>
)
