/** @jsx jsx */
import { jsx } from "theme-ui"

export const Container = props => (
  <div
    {...props}
    sx={{
      // variant: "styles.Container",
      //   bg: "red",
      mx: "auto",
      minWidth: 0,
      width: "100%",
      maxWidth: props.unconfined ? "unset" : "5xl",
      px: props.unconfined ? "unset" : 4,
    }}
  />
)
